import * as React from 'react';
import { Link, Text } from 'office-ui-fabric-react';
import { Stack, IStackTokens } from 'office-ui-fabric-react/lib/Stack';
import SignIn from './SignIn';
import { stage } from '../config';

const verticalGapStackTokens: IStackTokens = {
	childrenGap: 'm',
	padding: 'm'
};

export interface WelcomeProps {
	onSignIn(): void;
}

const Welcome: React.FC<WelcomeProps> = ({ onSignIn }) => {
	const handleSignIn = () => {
		onSignIn();
	};

	return (
		<Stack tokens={verticalGapStackTokens}>
			<Stack tokens={verticalGapStackTokens}>
				<div>
					<i className={`ms-Icon ms-fontSize-20 ms-Icon--Calories ms-fontColor-orangeLighter`} />
					{'  '}
					<span className="ms-font-m ms-fontColor-neutralSecondary">Historical smelter data</span>
				</div>
				<div>
					<i className={`ms-Icon ms-fontSize-18 ms-Icon--Globe2 ms-fontColor-green`} />
					{'  '}
					<span className="ms-font-m ms-fontColor-neutralSecondary">Compare smelter indices worldwide</span>
				</div>
				<div>
					<i className={`ms-Icon ms-fontSize-18 ms-Icon--Table ms-fontColor-blue`} />
					{'  '}
					<span className="ms-font-m ms-fontColor-neutralSecondary">Import smelter indices into Excel</span>
				</div>
			</Stack>
			<SignIn onSignIn={handleSignIn} />
			<Text className="ms-font-m ms-fontColor-neutralSecondary">
				New to Savant? {'  '}
				<Link className="ms-fontColor-blue" href={stage.signUpURL} target="_blank" rel="noopener">
					{' '}
					Sign up now
				</Link>
			</Text>
		</Stack>
	);
};

export default Welcome;
