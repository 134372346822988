import * as React from 'react';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react';
/* global Spinner */

export interface ProgressProps {
	logo: string;
	message: string;
}

const Progress: React.FC<ProgressProps> = ({ logo, message }) => {
	return (
		<section className="progress-centered ms-u-fadeIn500">
			<img width="90" height="90" src={logo} alt="logo" />
			<Spinner size={SpinnerSize.large} label={message} />
		</section>
	);
};

export default Progress;
