import 'office-ui-fabric-react/dist/css/fabric.min.css';
import App from './components/App';
import { AppContainer } from 'react-hot-loader';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
/* global AppContainer, Component, document, Office, module, require */
import Progress from './components/Progress';

initializeIcons();

const render = (Component, props) => {
	ReactDOM.render(
		<AppContainer>
			<Component {...props} />
		</AppContainer>,
		document.getElementById('container')
	);
};

Office.initialize = () => {};
/* Render application after Office initializes */
Office.onReady((info) => {
	console.log(`Office.js is now ready in ${info.host} on ${info.platform}`);
	render(App, {});
});
render(Progress, {
	logo: 'assets/earthi-logo.png',
	message: 'Loading ...'
});

if ((module as any).hot) {
	(module as any).hot.accept('./components/App', () => {
		const NextApp = require('./components/App').default;
		render(NextApp, {});
	});
}
