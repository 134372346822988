import { API } from 'aws-amplify';
import { getAuthHeaders } from './auth';

export const getPseudoGroups = async ({ type = 'cu' }) =>
	API.get('savant-api', '/pseudo-groups', {
		headers: await getAuthHeaders(),
		queryStringParameters: {
			type
		}
	});

export const getSmelterData = async ({ regions, startDate, endDate, type = 'cu' }) =>
	API.get('savant-api', `/smelter-data`, {
		headers: await getAuthHeaders(),
		queryStringParameters: {
			regions,
			startDate,
			endDate,
			type
		}
	});
