import * as moment from 'moment';

/*************************************environments**************************************/

const development = {
	apiGateway: {
		URL: 'https://savant-api-staging.earthi.world',
		REGION: 'eu-west-2'
	},
	cognito: {
		REGION: 'eu-west-2',
		USER_POOL_ID: 'eu-west-2_9qMuAsGfQ',
		APP_CLIENT_ID: '4dj8gnt3uh3qh5aeqm498po6e0',
		IDENTITY_POOL_ID: 'eu-west-2:4d70eff0-0679-4489-a15d-a76f6f325e87'
	},
	idleTimeout: 1000 * 60 * 60,
	signUpURL: 'https://savant-staging.earthi.world/signUp'
};

const production = {
	apiGateway: {
		URL: 'https://savant-api.earthi.world',
		REGION: 'eu-west-2'
	},
	cognito: {
		REGION: 'eu-west-2',
		USER_POOL_ID: 'eu-west-2_9e4g0FdJV',
		APP_CLIENT_ID: '31p9bp9us2j1g003pj0vl9i85a',
		IDENTITY_POOL_ID: 'eu-west-2:93f85d7c-3901-4f39-ab1e-9ced08f166eb'
	},
	idleTimeout: 1000 * 60 * 60,
	signUpURL: 'https://savant.earthi.world/signUp'
};

export const stage = process.env.NODE_ENV === 'production' ? production : development;

/*************************************date ranges**************************************/

export const dateFormat = 'YYYYMMDD';
// const dateFormat = 'YYYYMMDDhh:mm:ss';
const minDate = '20160301';

export enum DateRangeType {
	PAST_3_MONTHS,
	PAST_6_MONTHS,
	PAST_9_MONTHS,
	PAST_YEAR,
	PAST_2_YEARS,
	PREV_CALENDAR_YEAR,
	PREV_2_CALENDAR_YEARS,
	ALL_DATES,
	ANOTHER_DATE_RANGE
}

interface IDateRange {
	dateFrom(name: void): string;
	dateTo(name: void): string;
	text: string;
}

type DateRangeTypeInterface = { [key in DateRangeType]?: IDateRange };

export const DateRangeOptions: DateRangeTypeInterface = {
	[DateRangeType.PAST_3_MONTHS]: {
		dateFrom: () => moment.utc().subtract(3, 'M').format(dateFormat),
		dateTo: () => moment.utc().format(dateFormat),
		text: 'Past 3 months'
	},
	[DateRangeType.PAST_6_MONTHS]: {
		dateFrom: () => moment.utc().subtract(6, 'M').format(dateFormat),
		dateTo: () => moment.utc().format(dateFormat),
		text: 'Past 6 months'
	},
	[DateRangeType.PAST_9_MONTHS]: {
		dateFrom: () => moment.utc().subtract(9, 'M').format(dateFormat),
		dateTo: () => moment.utc().format(dateFormat),
		text: 'Past 9 months'
	},
	[DateRangeType.PAST_YEAR]: {
		dateFrom: () => moment.utc().subtract(1, 'y').format(dateFormat),
		dateTo: () => moment.utc().format(dateFormat),
		text: 'Past year'
	},
	[DateRangeType.PAST_2_YEARS]: {
		dateFrom: () => moment.utc().subtract(2, 'y').format(dateFormat),
		dateTo: () => moment.utc().format(dateFormat),
		text: 'Past 2 years'
	},
	[DateRangeType.PREV_CALENDAR_YEAR]: {
		dateFrom: () => moment.utc().subtract(1, 'y').startOf('y').format(dateFormat),
		dateTo: () => moment.utc().format(dateFormat),
		text: 'Previous calendar year'
	},
	[DateRangeType.PREV_2_CALENDAR_YEARS]: {
		dateFrom: () => moment.utc().subtract(2, 'y').startOf('y').format(dateFormat),
		dateTo: () => moment.utc().format(dateFormat),
		text: 'Previous 2 calendar years'
	},
	[DateRangeType.ALL_DATES]: {
		dateFrom: () => minDate,
		dateTo: () => moment.utc().format(dateFormat),
		text: 'All dates'
	}
};

/*************************************regions**************************************/

export const pseudoGroupsOrder = {
	cu: [ 'global', 'default', 'country', 'china' ],
	ni: [ 'global', 'default', 'grade', 'country' ]
};

/**************************mutually exclusive regions*****************************/

export const exclPseudoGroups = {
	globalexchina: [ 'asiaoceania', 'europeafrica', 'namerica', 'samerica' ],
	asiaoceania: [ 'globalexchina' ],
	europeafrica: [ 'globalexchina' ],
	namerica: [ 'globalexchina' ],
	samerica: [ 'globalexchina' ]
};

/*************************************metal types***********************************/
export const savantMetals = [ { key: 'cu', name: 'Copper' }, { key: 'ni', name: 'Nickel' } ];
