import * as React from 'react';
import { Auth } from 'aws-amplify';
import { useIdleTimer } from 'react-idle-timer';
import { ActionButton, IIconProps } from 'office-ui-fabric-react';
import { configureApi } from '../libs/auth';
import Header from './Header';
// import Progress from './Progress';
import Welcome from './Welcome';
import Smelters from './Smelters';
import { stage } from '../config';

const signOutIcon: IIconProps = { iconName: 'SignOut' };

const App: React.FC = () => {
	const [ userAuthenticated, setUserAuthenitacted ] = React.useState(false);

	React.useEffect(() => {
		Office.onReady(() => {
			configureApi();
		});
	}, []);

	const signOut = async () => {
		try {
			await Auth.signOut();
			setUserAuthenitacted(false);
			// const date = new Date();
			// console.log(`User signed out at ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`);
		} catch (err) {
			console.log(err);
		}
	};

	const handleSignIn = () => {
		setUserAuthenitacted(true);
	};

	useIdleTimer({
		timeout: stage.idleTimeout,
		onIdle: signOut
	});

	return (
		<div>
			<Header />
			{userAuthenticated ? (
				<React.Fragment>
					<div className="sign-out-btn">
						<ActionButton iconProps={signOutIcon} onClick={signOut}>
							Sign out
						</ActionButton>
					</div>
					<Smelters />
				</React.Fragment>
			) : (
				<React.Fragment>
					<Welcome onSignIn={handleSignIn} />
				</React.Fragment>
			)}
		</div>
	);
};

export default App;
