import * as React from 'react';
import * as moment from 'moment-msdate';
// import { DatePicker, DayOfWeek, IDatePickerStrings } from 'office-ui-fabric-react/lib/DatePicker';
import { DatePicker, DayOfWeek, IDatePickerStrings } from 'office-ui-fabric-react';

import { jsDate } from '../utils';

const dateDisplayFormat = 'MMM D, YYYY';
const minDate: Date = jsDate(moment('2016-03-01'));

const DayPickerStrings: IDatePickerStrings = {
	months: [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December'
	],

	shortMonths: [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ],

	days: [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ],

	shortDays: [ 'S', 'M', 'T', 'W', 'T', 'F', 'S' ],
	goToToday: 'Go to today',
	prevMonthAriaLabel: 'Go to previous month',
	nextMonthAriaLabel: 'Go to next month',
	prevYearAriaLabel: 'Go to previous year',
	nextYearAriaLabel: 'Go to next year',
	closeButtonAriaLabel: 'Close date picker',
	monthPickerHeaderAriaLabel: '{0}, select to change the year',
	yearPickerHeaderAriaLabel: '{0}, select to change the month',
	invalidInputErrorMessage: 'Invalid date format.'
};

const firstDayOfWeek = DayOfWeek.Sunday;

interface IDatePickerProps {
	uDate: string;
	label: string;
	onDateSelected(date: moment.Moment, key: string): void;
}

const SingleDatePicker: React.FC<IDatePickerProps> = ({ uDate, label, onDateSelected }) => {
	const [ selectedDate, setSelectedDate ] = React.useState(null);
	//updating state during rendering
	if (uDate && !moment(selectedDate).isSame(uDate, 'day')) {		
		setSelectedDate(jsDate(moment(uDate)));
	}

	const handleDateSelected = (date) => {
		setSelectedDate(date);
		onDateSelected(moment(date), label);
	};

	return (
		<div>
			<DatePicker
				isRequired={false}
				firstDayOfWeek={firstDayOfWeek}
				strings={DayPickerStrings}
				label={label}
				placeholder="Select a date..."
				ariaLabel="Select a date"
				minDate={minDate}
				maxDate={jsDate(moment().startOf('day'))}
				showMonthPickerAsOverlay={true}
				onSelectDate={handleDateSelected}
				value={selectedDate}
				formatDate={(d) => moment(d).format(dateDisplayFormat)}
			/>
		</div>
	);
};

export default SingleDatePicker;
