import * as React from 'react';
import { MessageBar, MessageBarType, PrimaryButton, ProgressIndicator, TextField } from 'office-ui-fabric-react';
import { Stack, IStackTokens } from 'office-ui-fabric-react/lib/Stack';
import { Auth } from 'aws-amplify';

const verticalGapStackTokens: IStackTokens = {
	childrenGap: 'm',
	padding: 'm'
};
export interface SignInProps {
	onSignIn(): void;
}

const SignIn: React.FC<SignInProps> = ({ onSignIn }) => {
	const [ username, setUsername ] = React.useState('');
	const [ password, setPassword ] = React.useState('');
	const [ error, setError ] = React.useState('');
	const [ isLoading, setIsLoading ] = React.useState(false);

	const onUsernameChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setUsername(e.target.value);
	};

	const onPasswordChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setPassword(e.target.value);
	};

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsLoading(true);
		setError('');
		try {
			await Auth.signIn(username, password);
			onSignIn();
		} catch (err) {
			setIsLoading(false);
			setError(err.message);
		}
	};

	const renderError = (error) => {
		return (
			<MessageBar
				messageBarType={MessageBarType.error}
				isMultiline={false}
				onDismiss={() => {
					setError('');
				}}
				dismissButtonAriaLabel="Close"
			>
				{error}
			</MessageBar>
		);
	};

	React.useEffect(() => {
		return () => {
			setIsLoading(false);
		};
	}, []);

	return (
		<Stack>
			<form onSubmit={handleSubmit}>
				<Stack tokens={verticalGapStackTokens} className="ms-depth-16">
					<TextField label="Username or Email" value={username} onChange={onUsernameChange} />
					<TextField
						label="Password"
						type="password"
						canRevealPassword
						value={password}
						onChange={onPasswordChange}
					/>
					<PrimaryButton type="submit">Log in</PrimaryButton>
				</Stack>
			</form>
			{isLoading && <ProgressIndicator />}
			{error && renderError(error)}
		</Stack>
	);
};

export default SignIn;
