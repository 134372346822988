import * as React from 'react';
import { ComboBox, IComboBoxOption, IComboBox } from 'office-ui-fabric-react/lib/index';
import { savantMetals } from '../config';

const allOptions: IComboBoxOption[] = savantMetals.map(({ key, name }) => ({
	key,
	text: name
}));

export interface IComboBoxProps {
	userMetals: Array<string>;
	onMetalSelected(name: string): void;
}

const MetalsComboBox: React.FC<IComboBoxProps> = ({ userMetals, onMetalSelected }) => {
	const [ selectedKey, setSelectedKey ] = React.useState<string>('');
	const [ options, setOptions ] = React.useState<IComboBoxOption[]>([]);

	React.useEffect(
		() => {
			const metalOptions = allOptions.filter(({ key }) => userMetals.includes(key as string));
			setOptions(metalOptions);
			const defaultMetal = (metalOptions.length && metalOptions.map(({ key }) => key)[0]) || '';
			setSelectedKey(defaultMetal as string);
			onMetalSelected(defaultMetal as string);
		},
		[ userMetals ]
	);

	const onChange = (ev: React.FormEvent<IComboBox>, option: IComboBoxOption): void => {
		setSelectedKey(option.key as string);
		onMetalSelected(option.key as string);
	};

	return <ComboBox selectedKey={selectedKey} label="Metal" autoComplete="on" options={options} onChange={onChange} />;
};

export default MetalsComboBox;
