import * as React from 'react';
import { ComboBox, IComboBoxOption, IComboBox } from 'office-ui-fabric-react/lib/index';
import { DateRangeType, DateRangeOptions } from '../config';

const items: IComboBoxOption[] = [
	...Object.entries(DateRangeOptions).map(([ key, { text } ]) => ({
		key: Number(key),
		text
	})),
	{ key: DateRangeType.ANOTHER_DATE_RANGE, text: 'Another date range' }
];

export interface IComboBoxProps {
	resetDateRanges: boolean;
	onDatesOptionSelected(name: number): void;
}

const DateRangesComboBox: React.FC<IComboBoxProps> = ({ resetDateRanges, onDatesOptionSelected }) => {
	const [ selectedKey, setSelectedKey ] = React.useState(DateRangeType.PAST_3_MONTHS);

	React.useEffect(
		() => {
			if (resetDateRanges) {
				setSelectedKey(DateRangeType.PAST_3_MONTHS);
				onDatesOptionSelected(DateRangeType.PAST_3_MONTHS);
			}
		},
		[ resetDateRanges ]
	);

	const onChange = (ev: React.FormEvent<IComboBox>, option: IComboBoxOption): void => {
		setSelectedKey(option.key as number);
		onDatesOptionSelected(option.key as number);
	};

	return (
		<ComboBox selectedKey={selectedKey} label="Date Ranges" autoComplete="on" options={items} onChange={onChange} />
	);
};

export default DateRangesComboBox;
