import * as React from 'react';

 const Header: React.FC = () => {
	return (
		<section className="ms-bgColor-themeLighterAlt">
			<img
				className="logo"
				width="173"
				height="60"
				src="assets/savant-earthi-logo.png"
				alt="savant-earthi-logo"
			/>
		</section>
	);
};

export default Header;
