import Amplify, { Auth } from 'aws-amplify';
import { stage } from '../config';

export const configureApi = () =>
	Amplify.configure({
		Auth: {
			mandatorySignIn: false,
			region: stage.cognito.REGION,
			userPoolId: stage.cognito.USER_POOL_ID,
			identityPoolId: stage.cognito.IDENTITY_POOL_ID,
			userPoolWebClientId: stage.cognito.APP_CLIENT_ID
		},
		API: {
			endpoints: [
				{
					name: 'savant-api',
					endpoint: stage.apiGateway.URL,
					region: stage.apiGateway.REGION
				}
			]
		}
	});

export const getAuthHeaders = async () => {
	try {
		const user = await Auth.currentAuthenticatedUser();
		const token = user.signInUserSession.idToken.jwtToken;
		return {
			Authorization: token
		};
	} catch (err) {
		console.log('Error getting auth headers', err);
		return {
			Authorization: null
		};
	}
};

export const getAuthenticatedUserGroups = async () => {
	try {
		const result = await Auth.currentSession();
		const idToken = result.getIdToken();

		return idToken.decodePayload()['cognito:groups'] || [];
	} catch (err) {
		console.log(err);
		return [];
	}
};
