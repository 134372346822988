import * as React from 'react';
import * as moment from 'moment-msdate';
import { PrimaryButton, MessageBar, MessageBarType, ProgressIndicator } from 'office-ui-fabric-react';
import { Stack, IStackTokens } from 'office-ui-fabric-react/lib/Stack';
import MetalsComboBox from './MetalsComboBox';
import SingleDatePicker from './SingleDatePicker';
import MultiSelectComboBox from './MultiSelectComboBox';
import DateRangesComboBox from './DateRangesComboBox';
import { DateRangeType, DateRangeOptions } from '../config';
import { getSmelterData } from '../libs/savant-api';
import { getAuthenticatedUserGroups } from '../libs/auth';
import { dateFormat } from '../config';
import { getMetalsFromGroups } from '../utils';

const verticalGapStackTokens: IStackTokens = {
	childrenGap: 'm',
	padding: 'm'
};
interface IData {
	t: string;
	a: number;
	f: number;
}

interface ICompare {
	name: string;
	data: Array<IData>;
}

export interface ISmelterData {
	combine: Array<IData>;
	compare: Array<ICompare>;
}

export interface ICompoundData {}

const from = 'From';
const to = 'To';

const Smelters: React.FC = () => {
	const [ allMetalKeys, setAllMetalKeys ] = React.useState<Array<string>>([]);
	const [ metalKey, setMetalKey ] = React.useState('');
	const [ dateFrom, setDateFrom ] = React.useState('');
	const [ dateTo, setDateTo ] = React.useState('');
	const [ err, setErr ] = React.useState('');
	const [ pseudoGroups, setPseudoGroups ] = React.useState([]);
	const [ showDatePickers, setShowDatePickers ] = React.useState(false);
	const [ resetDateRanges, setResetDateRanges ] = React.useState(false);
	const [ isLoading, setIsLoading ] = React.useState(false);

	React.useEffect(() => {
		let metals = [];
		const getMetals = async () => {
			const userGroups = await getAuthenticatedUserGroups();
			metals = getMetalsFromGroups(userGroups);
			if (metals.length === 0) {
				setErr(
					'A problem has occured on this page. Please try again. If this error continues please contact Earth-i support.'
				);
				return;
			}
			setAllMetalKeys(metals);
		};
		getMetals();
	}, []);

	React.useEffect(
		() => {
			if (showDatePickers && dateTo && dateFrom && moment(dateTo).isBefore(dateFrom, 'day')) {
				setErr('The start date must be before the end date');
				setDateFrom('');
				setDateTo('');
			}
			if (resetDateRanges) {
				setResetDateRanges(false);
			}
		},
		[ dateFrom, dateTo, resetDateRanges ]
	);

	const onMetalSelected = (key) => {
		setMetalKey(key);
		setPseudoGroups([]);
		setDateFrom('');
		setDateTo('');
		setShowDatePickers(false);
		setResetDateRanges(true);
		setErr('');
	};

	const onPseudoGroupsSelected = (pgs) => {
		setErr('');
		setPseudoGroups(pgs);
	};

	const handlePseudoGroupsErr = (err) => {
		setErr(err);
	};

	const handlingSelectedDate = (date, key) => {
		if (key === from) {
			setDateFrom(date.format(dateFormat));
		} else if (key === to) {
			setDateTo(date.format(dateFormat));
		}
	};

	const handleChosenDatesOption = (option) => {
		if (option === DateRangeType.ANOTHER_DATE_RANGE) {
			setShowDatePickers(true);
			setDateFrom('');
			setDateTo('');
		} else {
			setShowDatePickers(false);
			setDateFrom(DateRangeOptions[option].dateFrom());
			setDateTo(DateRangeOptions[option].dateTo());
		}
		setErr('');
	};

	const getData = async () => {
		if (showDatePickers && (!dateFrom || !dateTo)) {
			setErr('Invalid Date Range');
			setDateFrom('');
			setDateTo('');
			return;
		}
		setIsLoading(true);
		setErr('');
		try {
			const smelterData = await getSmelterData({
				regions: pseudoGroups.join(),
				startDate: dateFrom,
				endDate: dateTo,
				type: metalKey
			});
			if (smelterData.combine.length === 0) {
				setErr('No data for chosen data range');
				setIsLoading(false);
				return;
			}
			const firstDate = smelterData.combine[0].t;
			const lastDate = smelterData.combine[smelterData.combine.length - 1].t;
			setDateTo(moment.utc(lastDate).format(dateFormat));
			setDateFrom(moment.utc(firstDate).format(dateFormat));
			const tableData = getTableData(smelterData);
			await createTable(tableData);
		} catch (err) {
			setErr((err.response && err.response.data && err.response.data.message) || err.message);
			console.log('Error getting indices', err);
		}
		setIsLoading(false);
	};

	const createTable = async (tableData) => {
		try {
			await Excel.run(async (context) => {
				const currentWorksheet = context.workbook.worksheets.getActiveWorksheet();
				const usedRange = currentWorksheet.getUsedRangeOrNullObject(true /*valuesOnly*/);
				currentWorksheet.load('name');
				await context.sync();
				if (!usedRange.isNullObject) {
					currentWorksheet.tables.load('items');
					await context.sync();
					currentWorksheet.tables.items.forEach((t) => t.delete());
					await context.sync();
				}
				const f = (n) => (n >= 0 ? f(Math.floor(n / 26) - 1) + String.fromCharCode(65 + n % 26) : '');
				const maxHeaderCol = f(tableData.headers.length - 1);
				const indexTable = currentWorksheet.tables.add(`A1:${maxHeaderCol}1`, true /*hasHeaders*/);
				indexTable.name = `${currentWorksheet.name}IndexTable`;
				// console.log('data ', tableData.tableData.map((obj) => Object.keys(obj).map((key) => obj[key])));
				indexTable.getHeaderRowRange().values = [ tableData.headers.map(({ label }) => label) ];

				const arr = tableData.tableData.map((obj) => Object.keys(obj).map((key) => obj[key]));
				indexTable.rows.add(null /*add at the end*/, arr);
				// dateRange.numberFormat = [["[$-409]m/d/yy h:mm AM/PM;@"]];
				indexTable.columns.getItemAt(0).getRange().numberFormat = [ [ '[$-409]dd/mm/yyyy' ] ];
				indexTable.getRange().format.autofitColumns();
				indexTable.getRange().format.autofitRows();

				//move to top
				indexTable.getDataBodyRange().getCell(0, 0).select();

				return context.sync();
			});
		} catch (err) {
			setErr(err.message);
			console.error(err);
			setIsLoading(false);
		}
	};

	const getTableData = ({ compare, combine }: ISmelterData) => {
		let headers = [ { label: 'Date', key: 't' } ];
		const tableData = [];

		compare.forEach(({ name, data }, i) => {
			const capName = name[0].toUpperCase() + name.substring(1);
			data.forEach(({ t, f, a }, j) => {
				// Add the time column value to the table data if this is the first region iteration
				// or if we've somehow got a frame error from the api data and the row is missing
				if (i === 0 || !tableData[j]) {
					tableData[j] = {
						t: moment.utc(t).toOADate()
					};
				}

				// Generate the region-based f and σ column names
				let namefkey = `${capName} Inactive Capacity`;
				let nameakey = `${capName} Activity Dispersion`;

				// Add the column names to the table headers if this is the first data iteration
				if (j === 0) {
					headers = [
						...headers,
						...[
							{
								label: namefkey,
								key: namefkey
							},
							{
								label: nameakey,
								key: nameakey
							}
						]
					];
				}

				// Set the f column on the table data
				tableData[j][namefkey] = f;
				tableData[j][nameakey] = a;
			});
		});

		if (compare.length > 1) {
			combine.forEach(({ f, a }, k) => {
				// Generate the f column names
				let namefkey = `Combined Inactive Capacity`;
				let nameakey = `Combined Activity Dispersion`;

				// Add the column names to the table headers if this is the first data iteration
				if (k === 0) {
					headers = [
						...headers,
						...[
							{
								label: namefkey,
								key: namefkey
							},
							{
								label: nameakey,
								key: nameakey
							}
						]
					];
				}

				// Set the f column on the table data
				tableData[k][namefkey] = f;
				tableData[k][nameakey] = a;
			});
		}

		return {
			headers,
			tableData
		};
	};

	const renderErr = () => {
		return (
			<MessageBar
				messageBarType={MessageBarType.error}
				onDismiss={() => {
					setErr('');
				}}
				dismissButtonAriaLabel="Close"
			>
				{err}
			</MessageBar>
		);
	};

	if (err && allMetalKeys.length === 0) {
		return renderErr();
	}
	return (
		<Stack tokens={verticalGapStackTokens}>
			<MetalsComboBox userMetals={allMetalKeys} onMetalSelected={onMetalSelected} />
			<MultiSelectComboBox
				metalType={metalKey}
				onPseudoGroupsSelected={onPseudoGroupsSelected}
				onPseudoGroupsErr={handlePseudoGroupsErr}
			/>
			<DateRangesComboBox resetDateRanges={resetDateRanges} onDatesOptionSelected={handleChosenDatesOption} />
			{showDatePickers && (
				<React.Fragment>
					<SingleDatePicker uDate={dateFrom} label={from} onDateSelected={handlingSelectedDate} />
					<SingleDatePicker uDate={dateTo} label={to} onDateSelected={handlingSelectedDate} />
					{err && !dateFrom && !dateTo && renderErr()}
				</React.Fragment>
			)}
			<PrimaryButton onClick={getData}>Get Data</PrimaryButton>
			{isLoading && <ProgressIndicator barHeight={4} />}
			{err && ((dateFrom && dateTo && showDatePickers) || !showDatePickers) && renderErr()}
		</Stack>
	);
};

export default Smelters;
