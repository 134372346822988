import * as React from 'react';
import {
	ComboBox,
	IComboBox,
	IComboBoxStyles,
	IComboBoxOption,
	SelectableOptionMenuItemType
} from 'office-ui-fabric-react/lib/ComboBox';
import { pseudoGroupsOrder, exclPseudoGroups } from '../config';
import { getPseudoGroups } from '../libs/savant-api';

const comboBoxStyles: Partial<IComboBoxStyles> = {
	optionsContainer: { padding: 20 },
	callout: { '.ms-Checkbox.is-checked': { backgroundColor: '#edebe9' } }
};

export interface IRegionOption extends IComboBoxOption {
	key: string;
	text: string;
	itemType?: SelectableOptionMenuItemType;
	disabled?: boolean;
	type?: string;
}

export interface DropdownProps {
	metalType: string;
	onPseudoGroupsSelected(name: Array<string>): void;
	onPseudoGroupsErr(err: string): void;
}

const INITIAL_OPTIONS: IRegionOption[] = [
	{
		key: 'global-global',
		//note: additional spaces for text needed because of bug coming from Combobox component
		text: 'Global   ',
		type: 'global'
	}
];

const MultiSelectComboBox: React.FC<DropdownProps> = ({ metalType, onPseudoGroupsSelected, onPseudoGroupsErr }) => {
	const [ selectedPseudoGroups, setSelectedPseudoGroups ] = React.useState([ 'global-global' ]);
	const [ regionOptions, setRegionOptions ] = React.useState(INITIAL_OPTIONS);

	const groupByType = (pg) =>
		pg.reduce((r, a) => {
			r[a.type] = [ ...(r[a.type] || []), a ];

			return r;
		}, {});

	const onChange = (event: React.FormEvent<IComboBox>, item: IRegionOption): void => {
		if (item) {
			setSelectedPseudoGroups(
				item.selected
					? [ ...selectedPseudoGroups, item.key as string ]
					: selectedPseudoGroups.filter((key) => key !== item.key)
				// regionOptions: updatedOptions
			);
		}
	};

	const onDismiss = (): void => {
		const pgs = selectedPseudoGroups.map((key) => key.slice(0, key.lastIndexOf('-')));
		onPseudoGroupsSelected(pgs);
	};

	React.useEffect(
		() => {
			if (!metalType) {
				return;
			}

			onPseudoGroupsSelected([ 'global' ]);
			const getPG = async () => {
				let pg = [];
				try {
					pg = await getPseudoGroups({ type: metalType });
				} catch (err) {
					onPseudoGroupsErr("Couldn't get regions! Please try again, or contact Earth-i support");
					return;
				}
				const groupedPG = groupByType(pg);
				Object.keys(groupedPG).forEach((key) => {
					if (key !== 'default') {
						groupedPG[key].sort((pg1, pg2) => {
							if (pg1.key < pg2.key) {
								return -1;
							}
							if (pg1.key > pg2.key) {
								return 1;
							}
							return 0;
						});
					}
				});
				const regionOptions = [ ...INITIAL_OPTIONS ];
				const groupedPGKeys = Object.keys(groupedPG).sort(
					(r1, r2) => pseudoGroupsOrder[metalType].indexOf(r1) - pseudoGroupsOrder[metalType].indexOf(r2)
				);
				groupedPGKeys.forEach((key, idx) => {
					regionOptions.push({
						key: key,
						text: key.toUpperCase(),
						itemType: SelectableOptionMenuItemType.Header
					});
					groupedPG[key].forEach(({ key, name, type }) =>
						regionOptions.push({ key: `${key}-${type}`, text: name, type: type })
					);
					if (idx < groupedPGKeys.length - 1) {
						regionOptions.push({
							key: `divider_${idx}`,
							text: '-',
							itemType: SelectableOptionMenuItemType.Divider
						});
					}
				});

				setRegionOptions(regionOptions);
				setSelectedPseudoGroups([ 'global-global' ]);
				// onPseudoGroupsSelected([ 'global' ]);
			};
			getPG();
		},
		[ metalType ]
	);

	React.useEffect(
		() => {
			const isSelected = selectedPseudoGroups.length > 0;
			let updatedOptions = [];
			const selectedKeys = selectedPseudoGroups.map((key) => key.slice(0, key.lastIndexOf('-')));
			if (isSelected) {
				updatedOptions = regionOptions.map((o) => {
					if (
						('type' in o &&
							o.type !== selectedPseudoGroups[0].slice(selectedPseudoGroups[0].lastIndexOf('-') + 1)) ||
						(exclPseudoGroups &&
							selectedKeys.some(
								(k) =>
									exclPseudoGroups[k] &&
									exclPseudoGroups[k].includes(o.key.slice(0, o.key.lastIndexOf('-')))
							))
					) {
						o.disabled = true;
						o.styles = {
							optionText: {
								color: '#a19f9d'
							}
						};
					} else if ('type' in o) {
						o.disabled = false;
						o.styles = {
							optionText: {
								color: '#201f1e'
							}
						};
					}
					return o;
				});
			} else {
				updatedOptions = regionOptions.map(({ disabled, styles, ...options }) => options);
			}

			setRegionOptions(updatedOptions);
		},
		[ selectedPseudoGroups ]
	);

	return (
		<ComboBox
			onMenuDismiss={onDismiss}
			label="Regions"
			selectedKey={selectedPseudoGroups}
			// eslint-disable-next-line react/jsx-no-bind
			onChange={onChange}
			multiSelect={regionOptions.length > 1}
			options={regionOptions}
			errorMessage={
				regionOptions.length && selectedPseudoGroups.length === 0 ? 'At least on region must be selected' : ''
			}
			dropdownWidth={270}
			styles={regionOptions.length > 1 ? comboBoxStyles : null}
		/>
	);
};

export default MultiSelectComboBox;
